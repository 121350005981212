import React, { Component } from 'react'
import style from './Navbar.module.css'
import logo from '../../static/logo.png'
import {Link} from 'react-router-dom';

class Navbar extends Component {
    render() {
        return (
            <React.Fragment>
                <nav className={style.navList}>
                    {/* <ul className={style.navList}>
                        <li className={style.tab}>
                            <p>
                                Home
                            </p>
                        </li>
                        <li className={style.tab}>
                            <p>
                                Projects
                            </p>
                        </li>
                        <li> */}
                            <img src={logo} alt="logo" className={style.logo} />
                        {/* </li>
                        <li className={style.tab}>
                            <p>
                                Experience
                            </p>
                        </li>
                        <li className={style.tab}>
                            <p>
                                Contact
                            </p>
                        </li>
                    </ul> */}
                </nav>
                <div className={style.misc}>
                    <Link to="/4yuan">
                        <div className={style.yuan}></div>
                    </Link>
                </div>
            </React.Fragment>
        )
    }
}

export default Navbar