import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import Navbar from './Navbar/Navbar'
import Home from './Home/Home'
import Yuan from './Yuan/Yuan'
import Footer from './Footer/Footer'

import style from './Main.module.css'
import gsap from 'gsap';

//Create a Main Component
class Main extends Component {

    componentDidMount() {
        gsap.to("#welcome", {opacity:0, display: "none", duration: 2.5})
    }

    render() {
        return (
            <div className={style["main-container"]}>
                <Navbar></Navbar>
                <section className={style.content}>
                    <Switch>
                        {/* Render Different Component based on Route
                    <SidebarLayout exact path="/" component={Home} />

                    <SidebarLayout path="/topics/:topic" component={TopicPage} />

                    <Route path="/login" component={Login} />
                    <Route path='/signup' component={SignUp} />

                    <Route path="/questions/:questionId" component={QuestionPage} />
                    <Route path="/profile/:uid" component={ProfilePage} />
                    <Route path="/profile/:uid" render={(props) => <ProfilePage {...props}></ProfilePage>} />
                    <Route path="/messages/:uid" component={MessagesPage} />
                    <Route path="/content" component={ContentPage} />
                    <Route path="/stats" component={AnalysisPage} /> */}
                        <Route path="/" exact component={Home} />
                        <Route path="/4yuan" exact component={Yuan} />
                    </Switch>
                </section>
                <Footer></Footer>
                <div id="welcome" className={style.welcome}></div>
            </div>
        )
    }
}
//Export The Main Component
export default Main;