import { DUMMY_ACTION } from '../actions'

const initialState = {
    context: ""
}

export default (state = initialState, action) => {
    switch (action.type) {
        case DUMMY_ACTION: {
            const { payload } = action
            return {
                ...state,
                payload
            }
        }
        default:
            return state;
    }
}