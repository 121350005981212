import React, { Component } from 'react';
import resume_file from '../../static/resume-08-20.pdf';
import gsap from 'gsap';

import style from './Home.module.css';

class Home extends Component {

    constructor(props) {
        super(props);
        this.banner = React.createRef();
    }

    componentDidMount() {
        gsap.from(this.banner.current, {y: 200, opacity:0, duration: 1.3, ease:"slow(0.3, 0.7, false)"});
    }

    render() {
        return (
            <React.Fragment>
                <section className={style.jumbotron}>
                    <div className={style.background}></div>
                    <div className={style['banner-content']} ref={this.banner}>
                        <h1 className={style['banner-title']}>I'm Yuxiang Chen</h1>
                        <p className={style['banner-subtitle']}>Innovative Application Developer</p>
                    </div>
                </section>
                <section className={style.container}>
                    {/* <h1>Home</h1> */}
                    <a href={resume_file} download="resume" style={{ "textDecoration": "none", "color": "black" }}>
                        <div className={style['resume-button']}>
                            Get Resume
                        </div>
                    </a>
                    <p className={style.intro}>Currently a student in Master of Science in Software Engineering at San Jose State University. Graduated from Iowa State University. GPA: 3.66 Computer Engineering major. Actively looking for internships related to software development. Pick up new technology fast. Great learner. Please contact me if you are looking for people who have strong potential in engineering.</p>
                </section>
            </React.Fragment>
        )
    }
}

export default Home