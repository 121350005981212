import React, { Component } from "react";
import gsap from "gsap";
import style from "./Yuan.css";
import mirror from './mirror.png';

class Yuan extends Component {
  constructor(props) {
    super(props);
    this.banner = React.createRef();
  }

  componentDidMount() {
    gsap.from(this.banner.current, {
      y: 200,
      opacity: 0,
      duration: 1.3,
      ease: "slow(0.3, 0.7, false)",
    });

    // ——————————————————————————————————————————————————
// TextScramble
// ——————————————————————————————————————————————————

class TextScramble {
    constructor(el) {
      this.el = el
      this.chars = '!<>-_\\/[]{}—=+*^?#________'
      this.update = this.update.bind(this)
    }
    setText(newText) {
      const oldText = this.el.innerText
      const length = Math.max(oldText.length, newText.length)
      const promise = new Promise((resolve) => this.resolve = resolve)
      this.queue = []
      for (let i = 0; i < length; i++) {
        const from = oldText[i] || ''
        const to = newText[i] || ''
        const start = Math.floor(Math.random() * 40)
        const end = start + Math.floor(Math.random() * 40)
        this.queue.push({ from, to, start, end })
      }
      cancelAnimationFrame(this.frameRequest)
      this.frame = 0
      this.update()
      return promise
    }
    update() {
      let output = ''
      let complete = 0
      for (let i = 0, n = this.queue.length; i < n; i++) {
        let { from, to, start, end, char } = this.queue[i]
        if (this.frame >= end) {
          complete++
          output += to
        } else if (this.frame >= start) {
          if (!char || Math.random() < 0.28) {
            char = this.randomChar()
            this.queue[i].char = char
          }
          output += `<span class="dud">${char}</span>`
        } else {
          output += from
        }
      }
      this.el.innerHTML = output
      if (complete === this.queue.length) {
        this.resolve()
      } else {
        this.frameRequest = requestAnimationFrame(this.update)
        this.frame++
      }
    }
    randomChar() {
      return this.chars[Math.floor(Math.random() * this.chars.length)]
    }
  }
  
  // ——————————————————————————————————————————————————
  // Example
  // ——————————————————————————————————————————————————
  
  const phrases = [
    '王后：魔镜',
    '王后：魔镜...',
    '王后：谁才是这个世界上...',
    '王后：最美丽的女人？',
    '魔镜：王后的美貌是有名的...',
    '魔镜：但是，我另外见到一位少女...',
    '魔镜：辛苦打工也遮挡不住她闲雅的美貌...',
    '魔镜：她才是世界上...',
    '魔镜：最美丽的人。',
    '王后：不可能的！',
    '王后：没有人可以比我更漂亮。',
    '王后：你快说她是谁！？',
    '魔镜：她，唇红如玫瑰，发灰带粉...',
    '王后：梦雪公主？！',
    '王后：来人啊！！快来人！',
    '王后：我要让CYX为梦雪公主带去我的妇女节祝福！',
    'CYX：祝我的女神袁梦雪公主节日快乐！！！！',
  ]
  
  const el = document.querySelector('.text')
  const fx = new TextScramble(el)
  
  let counter = 0
  const next = () => {
    if (counter === phrases.length) return;
    fx.setText(phrases[counter]).then(() => {
      setTimeout(next, 800)
    })
    counter = (counter + 1)
  }
  
  next()
  }

  render() {
    return (
      <React.Fragment>
        {/* <section className={style.container}>
                    <h1>Yuan</h1>
                    <h2>Resources</h2>
                    <h3>Python</h3>
                    <ul>
                        <li><a href="https://www.w3school.com.cn/python/index.asp">W3School [CN]</a></li>
                        <li><a href="https://www.w3schools.com/python/default.asp">W3School [EN]</a></li>
                    </ul>
                </section> */}

        <div className="container">
          <img src={mirror} alt="mirror" className="mirror"/>
          <div className="text"></div>
        </div>
      </React.Fragment>
    );
  }
}

export default Yuan;
